import React from 'react'
import Stack from '@mui/material/Stack'
import GeneralHelper from '../../helpers/GeneralHelper'
import * as Texts from './Text'
import Link from '@mui/material/Link'
import moment from 'moment'

const TextWUrl = ({
    message,
    ...props
}) => {
    let msgArr = GeneralHelper.urlify(message, true)
    // console.log(msgArr)
    
    let renderInput = []
    msgArr.map((msg, idx) => {
        if(!GeneralHelper.validURL(msg))
        {
            return renderInput.push(<Texts.Text
                key={`bubble-url-${idx}-${moment().format('yyyy/mm/dd hh:mm:ss')}`}
                {...props}
            >{msg}</Texts.Text>)
        }
        return renderInput.push(<Link key={`bubble-url-${idx}-${moment().format('yyyy/mm/dd hh:mm:ss')}`} href={msg}　 target="_blank" color="background.highlight">
        {'参考リンク'}
      </Link>)
    })
    return <Stack direction="column">{renderInput}</Stack>
    // return <Texts.Text
    //     {...props}
    // >{message}</Texts.Text>
}

export default TextWUrl