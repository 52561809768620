import AuthRequestHelper from "./AuthRequestHelper"

class ChatHelper{
    //get questionnaire user by questionnaire user id
    async getChatLogByUserId(userid){
        try{
            const requestUrl="chatlogbyuserId/"+userid
            let roleData=await AuthRequestHelper.requestNoToken("get",requestUrl,{})   
            return roleData.data   
        }
        catch(error){
            console.log(error)
            return {}
        }
    }

    //store chat log
    //Note: log must be a stringified JSON object
    async storeChatLogByUserId(userId,chatSessionId,log){
        try{
            const requestUrl="addchatlog"
            const chatParams={
                "user_id": userId,
                "chat_session_id":chatSessionId,
                "log": JSON.stringify(log)
            }
            return await AuthRequestHelper.request("put",requestUrl,chatParams)
        }
        catch(error){
            console.log(error)
            return ""
        }
    }

}

export default new ChatHelper();