import React, { Component, useState, useEffect } from 'react'
import {
    Grid,
    Typography,
    Drawer,
    Toolbar,
    Divider,
    Box,
    Stack,
    CssBaseline,
    List,
    ListItem,
    TextField
} from '@mui/material'
import * as Loaders from '../../components/UIComponents/Loader'
import * as Icons from '../../components/UIComponents/Icons'
import * as Buttons from '../../components/UIComponents/Button'
import * as Texts from '../../components/UIComponents/Text'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import MUIIconButton from '@mui/material/IconButton'
import ChatInput from '../../components/ChatInput'
import config from '../../config/config'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import moment from 'moment'
// import 'dayjs/locale/ja';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ja from 'date-fns/locale/ja'
import { CalendarPicker, LocalizationProvider } from '@mui/x-date-pickers';

const { innerWidth, innerHeight } = window
const allowedType = ["datepick", "check", "radio", "radio, text", "check, text", "COMPLETE"]

/**
 * 
qObj format
{
    count: "END",
    options: {
        "1": {
            "option_text": botLang["return"],
            "option_index": 100
        }
    },
    qDesc: "",
    qID: "END",
    survey_index: -1,
    type: "radio"
}
 */

const OptionPanel = ({
    options,
    type,
    qIndex,
    qObj,
    onSubmit,
    optionClickHint,
    optionSubmitHint
}) => {
    // console.log(`option type ${type}`)
    const [toConfirmAnswer, setToConfirmAnswer] = useState("")
    const [toSubmitQKey, setToSubmitQKey] = useState(null)
    const [inputdate, setInputDate] = useState(moment())
    const [resetAnswer, setResetAnswer] = useState(false)
    
    // prevent crashing, this component will not render without data input
    // if(!options || Object.keys(options).length <= 0 || !type) return null
    if(!allowedType.includes(type) || !type) return null
    // console.log("to render", options)
    let toRenderOptions = []
    switch(type)
    {
        case "datepick":
            // console.log("should render datepick")
            toRenderOptions.push(<LocalizationProvider 
                key={`datepicker-component`}
                dateAdapter={AdapterDateFns}
                // adapterLocale="ja"
                locale={ja}
                // localeText={jaJP.components.MuiLocalizationProvider.defaultProps.localeText}
            >
                <MobileDatePicker
                    // label="Date mobile"
                    views={['year', 'month']}
                    inputFormat="yyyy-MM"
                    value={inputdate}
                    onChange={v => {
                        setInputDate(moment(v))
                        setToConfirmAnswer(moment(v).format('yyyy-MM'))
                        setToSubmitQKey(moment(v).format('yyyy-MM'))
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    resetAnswer={resetAnswer}
                />
                </LocalizationProvider>
            )
        break
        case "check, text":
        case "check":
            // console.log("got check type")
            toRenderOptions.push(
                <CheckboxGroup 
                    key={`checkbox-component`}
                    options={options}
                    onSelect={(qKeyArr) => {
                        // console.log(`received array `, qKeyArr)
                        // console.log(`available options`, options)
                        // find the answer and submit to the chatbot
                        // let answerArr = []
                        /*
                        qKeyArr.map((qKey, idx) => {
                            let answerText = options[qKey]?.option_text
                            // console.log(`answer text `, answerText)
                            answerArr.push(answerText)
                        })
                        */
                        // console.log(`full answer arr`, answerArr)
                        let fullAnswer = qKeyArr.join(",")
                        // console.log(`joined full answer`, fullAnswer)
                        setToConfirmAnswer(fullAnswer)
                        setToSubmitQKey(qKeyArr.length > 1 ? qKeyArr:qKeyArr.join(""))
                    }}
                    resetAnswer={resetAnswer}
                />)
        break
        case "radio":
        case "radio, text":
        case "COMPLETE":
        default:
            // console.log("should render radio")
            toRenderOptions.push(
            <RadioGroup 
                key={`radio-component`}
                options={options}
                onSelect={(qKey) => {
                    // find the answer and submit to the chatbot
                    let answerText = options[qKey]?.option_text
                    setToConfirmAnswer(answerText)
                    // add the selected qKey for reference
                    setToSubmitQKey(options[qKey]?.option_index)
                }}
                resetAnswer={resetAnswer}
            />)
    }
    // console.log("checkpoint 1")
    return (<Stack
        sx={{
            // display: 'none',
            width: 1,
            flexWrap: 'wrap',
            // px: 2,
            // py: 1,
            // bgcolor: 'primary.light',
            // maxHeight: config.optionPanelHeight
        }}
    >
        {toConfirmAnswer !== "" && <Box
            id="answer_confirm"
            sx={{
                width: 1,
                minHeight: 48,
                px: 4,
                py: 2,
                width: 'inherit',
                bgcolor: 'rgb(0, 205, 216,.9)',
                display: 'flex',
                justifyContent: "center",
                alignItems:"center",
                position: 'relative'
            }}
            onClick={(e) => {
                // determine if it's the response box or the arrow
                if(!e.target?.className?.includes("answer_confirm")) return e.preventDefault()
                // alert("send")
                // console.log("it should only be called once")
                // console.log("to submit qkey ", toSubmitQKey)

                switch(type)
                {
                    case "datepick":
                        // console.log("the dates are", options)
                        // console.log("The next index is ",options)
                        let nextOption = options["1"]
                        nextOption["option_text"] = toConfirmAnswer
                        onSubmit(nextOption, nextOption["next_index"], toConfirmAnswer, type)
                        setToConfirmAnswer("")
                        setToSubmitQKey(null)
                        setResetAnswer(!resetAnswer)
                    break
                    case "COMPLETE":
                    //     onSubmit(qIndex, qIndex+1, toConfirmAnswer, type)
                    //     setToConfirmAnswer("")
                    //     setToSubmitQKey(null)
                    //     setResetAnswer(!resetAnswer)
                    // break
                    default:
                        // let qKeyArr = toSubmitQKey.split(",")
                        // console.log("qKey ARR", qKeyArr)
                        // let answerArr = [], nextQuestionIndex = -1, optionsArr = [], hasOtherIndex = -1
                        try
                        {
                            // console.log(`the next question index is ${nextQuestionIndex}`)
                            onSubmit([], toSubmitQKey, toConfirmAnswer, type)
                            setToConfirmAnswer("")
                            setToSubmitQKey(null)
                            setResetAnswer(!resetAnswer)
                        }
                        catch(e)
                        {
                            // console.log("answer submit error", e)
                        }

                }


                // alert("clicked")
            }}
            className="answer_confirm"
        >
            <Buttons.Icon 
                id="answer_cancel"
                icon={<Icons.CancelIcon 
                    width="8"
                    height="8"
                />}
                sx={{
                    position: 'absolute',
                    top: 4,
                    right: 4
                }}
                onClick={(e) => {
                    // alert("cancel")
                    setToConfirmAnswer("")
                }}
            />
            <Texts.Paragraph className="answer_confirm">{toConfirmAnswer}</Texts.Paragraph>
        </Box>}
        <Stack
            direction="column"
            sx={{
                height: config.optionPanelHeight,
                width: 1,
                flexWrap: 'wrap',
                px: 2,
                pt: 1,
                bgcolor: 'primary.light'
            }}
        >
            <Box
                sx={{
                    width: 'inherit',
                    maxHeight: 48
                }}
            >
                {toConfirmAnswer !== "" ? 
                <Texts.Tip>{optionClickHint}</Texts.Tip>
                :
                <Texts.Tip>{optionSubmitHint}</Texts.Tip>}
            </Box>
            <Box
                sx={{
                    width: 1,
                    flexWrap: "wrap",
                    justifyContent: 'flex-end',
                    alignItems: "flex-end",
                    maxHeight: config.optionPanelHeight - 48, //config.optionPanelHeight - 48, this allows the top instruction box to show
                    overflow: 'auto',
                    pb: 2,
                    bgcolor: 'primary.light'
                }}
            >
                {toRenderOptions}
                {/*<Stack
                    direction="row"
                    spacing={1}
                    sx={{
                        mt: 1,
                        // flexGrow: 1,
                        flexWrap: "wrap",
                        justifyContent: 'flex-end',
                        alignItems: "flex-end",
                        overflow: 'auto',
                        minHeight: 64
                    }}
                >
                    {toRenderOptions}
                </Stack>*/}
            </Box>

        </Stack>

    </Stack>)
}

const RadioUnit = ({
    title,
    selected,
    onClick
}) => <Buttons.OutlinedButton 
title={title} 
onClick={onClick}
checked={selected}
sx={{
    mt: .5,
    mr: .5
}}
/>

const RadioGroup = ({
    options,
    onSelect,
    resetAnswer
}) => {
    // console.log("radiogroup options", options)
    // only one answer can be selected, so once it's selected the answer will be added to the parent group
    const [selectedIndex, setSelectedIndex] = useState(null)
    useEffect(() => {
        setSelectedIndex(null)
    }, [resetAnswer])
    if(!options || Object.keys(options).length <= 0) return null

    let toRenderOptions = []
    Object.keys(options).map((k, idx) => {
        const targetOption = options[k]
        // console.log("target option", targetOption)
        /*
        toRenderOptions.push(<Box key={`radio-unit-${k}-${idx}`}>
            <RadioUnit 
                title={targetOption} 
                selected={selectedIndex === idx}
                onClick={async () => {
                    onSelect(k) // use the key to retrieve the answer
                    setSelectedIndex(idx)
                }}
            />
        </Box>)
        */
    //    toRenderOptions.push(<div>Test</div>)
        toRenderOptions.push(
            <RadioUnit 
                key={`radio-unit-${k}-${idx}`}
                title={targetOption?.option_text} 
                selected={selectedIndex === idx}
                onClick={async () => {
                    onSelect(k) // use the key to retrieve the answer
                    setSelectedIndex(idx)
                }}
            />
        )
        // console.log("test")
    })
    // console.log("to render options", toRenderOptions)
    return <>{toRenderOptions}</>
    // return toRenderOptions
}

const CheckboxUnit = ({
    title,
    selected,
    onClick
}) => <Buttons.CheckButton 
    title={title} 
    checked={selected} 
    onClick={onClick}
    sx={{
        mt: .5,
        mr: .5
    }}
/>

const CheckboxGroup = ({
    options,
    onSelect,
    resetAnswer
}) => {
    const [selectedIndex, setSelectedIndex] = useState([])
    useEffect(() => {
        setSelectedIndex([])
    }, [resetAnswer])

    if(!options || Object.keys(options).length <= 0) return null

    let toRenderOptions = [], finalIdx = Object.keys(options).length - 1
    Object.keys(options).map((k, idx) => {
        const targetOption = options[k]
        // console.log(`target option `,targetOption)
        /*
        toRenderOptions.push(<Box key={`radio-unit-${k}-${idx}`}>
            <CheckboxUnit 
                title={targetOption} 
                selected={selectedIndex.includes(idx)}
                onClick={() => {
                    let mutatedArr = [...selectedIndex]
                    if(mutatedArr.includes(idx)) // it's selected, should deselect
                    {
                        mutatedArr.splice(mutatedArr.indexOf(idx), 1)
                        setSelectedIndex(mutatedArr)
                        return onSelect(mutatedArr)
                    }
                    mutatedArr.push(idx)
                    onSelect(mutatedArr) // use the key to retrieve the answer
                    return setSelectedIndex(mutatedArr)
                }}
            />
        </Box>)
        */
        toRenderOptions.push(
            <CheckboxUnit 
                key={`radio-unit-${k}-${idx}`}
                title={targetOption?.option_text} 
                selected={selectedIndex.includes(targetOption?.option_text)}
                onClick={() => {
                    let mutatedArr = [...selectedIndex]
                    // console.log("selected array", mutatedArr)
                    if(mutatedArr.includes(targetOption?.option_text)) // it's selected, should deselect
                    {
                        mutatedArr.splice(mutatedArr.indexOf(targetOption?.option_text), 1)
                        // console.log("splice ",mutatedArr)
                        setSelectedIndex(mutatedArr)
                        return onSelect(mutatedArr)
                    }
                    if(k === finalIdx.toString())
                    {
                        // console.log("this is nothign")
                        // clean all the selection
                        setSelectedIndex([targetOption?.option_text])
                        return onSelect([targetOption?.option_text])
                    }
                    if(k !== finalIdx.toString() && mutatedArr.includes("該当なし"))
                    {
                        mutatedArr.splice(mutatedArr.indexOf("該当なし"), 1)
                    }
                    mutatedArr.push(targetOption?.option_text)
                    onSelect(mutatedArr) // use the key to retrieve the answer
                    // console.log("final array", mutatedArr)
                    return setSelectedIndex(mutatedArr)
                }}
            />
        )
        // console.log("test")
    })
    // console.log("to render options", toRenderOptions)

    return toRenderOptions
}

export default OptionPanel