import config from '../config/config'
class BotHelper {
    constructor() {}
    
    async queryBot(message = "", type = "question") // or option
    {
      // console.log(`the server url is ${config.localAuth.apiServiceUrl}`)
      // console.log(`process env `,process.env.NODE_ENV)
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      
      let raw = {}
      raw[type]=message
      raw = JSON.stringify(raw)
      
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      let botVersion=process.env.REACT_APP_CHAT_BOT_ENDPOINT.split(',')
      let queryBotType = "nccchatbot"
      
      const r = await fetch(`${config.localAuth.apiServiceUrl}${queryBotType}`, requestOptions)
        .then(response => response.json())
        .then(result => this.parseAnswer(result?.response))
        .catch(error => false);
      return r
    }

    parseAnswer(response)
    {
      // console.log("the received result", response)
      let parsedResponse = {}
      parsedResponse["results"] = {}
      // console.log("response type is ",response["type"])
      switch(response?.type)
      {
        case "answer":
          // console.log("go for answering")

          parsedResponse["type"]="answers"
          let pathURLObj = {}
          if(response?.path_url && Object.keys(response?.path_url).length > 0)
          {
            // console.log("found path URL")
            let originPathURLObj = response?.path_url
            Object.keys(originPathURLObj).map((k, idx) => {
              // console.log("key ", k)
              try
              {
                const targetObj = originPathURLObj[k]
                // console.log("target obj", targetObj)
                if(targetObj?.path || targetObj?.url)
                {
                  pathURLObj[k]={}
                  // console.log("true")
                  pathURLObj[k]["url"] = targetObj["path"] || "https://ganjoho.jp/public/index.html"
                  pathURLObj[k]["path"] = targetObj["url"] || "参考資料"
                  // console.log("path URL obj",pathURLObj)
                }
              }
              catch(e){}
            })
            // console.log("you should not see me before ended")
            parsedResponse["results"]["path_url"]=pathURLObj
          }
          if(response?.text)
          {
            parsedResponse["results"]["asnwer_text"]=response?.text
          }
/**
ORIGINAL
{
    "response": {
        "results": {
            "asnwer_text": "初期治療の流れと考え方のことでしたら、こちらの情報をご確認ください。ご参考になりましたら幸いです。",
            "path_url": {
                "1": {
                    "path": null,
                    "url": null
                },
                "2": {
                    "path": null,
                    "url": null
                },
                "3": {
                    "path": null,
                    "url": null
                },
                "4": {
                    "path": "ガイドライン",
                    "url": "https://jbcs.xsrv.jp/guidline/p2019/guidline/g4/q19/"
                },
                "5": {
                    "path": null,
                    "url": null
                },
                "6": {
                    "path": null,
                    "url": null
                }
            }
        },
        "type": "answers"
    }
}
NEW
{
    "response": {
        "path_url": {
            "1": {
                "path": "https://ganjoho.jp/public/dia_tre/dia_tre_diagnosis/second_opinion.html",
                "url": "診断と治療"
            },
            "2": {
                "path": NaN,
                "url": NaN
            },
            "3": {
                "path": NaN,
                "url": NaN
            },
            "4": {
                "path": NaN,
                "url": NaN
            },
            "5": {
                "path": NaN,
                "url": NaN
            }
        },
        "text": "セカンドオピニオンのことでしたら、こちらの情報をご確認ください。ご参考になりましたら幸いです。",
        "type": "answer"
    }
}
 */
        break
        case "interpreting":
          parsedResponse["type"]="summarizations"
          let parsedArr = []
          // console.log("go for interpretation")
          if(response?.option && Object.keys(response?.option).length > 0)
          {
            let originOptURLObj = response?.option
            // console.log("test", originOptURLObj)
            Object.keys(originOptURLObj).map((k, idx) => {
              try
              {
                const targetObj = originOptURLObj[k]
                if(targetObj?.text)
                {
                  parsedArr.push({
                    summary: targetObj?.text
                  })
                }
              }
              catch(e){}
            })
            parsedResponse["results"]=parsedArr
          }
          if(response?.text)
          {
            parsedResponse["asnwer_text"]=response?.text
          }
/**
ORIGINAL
{
    "response": {
        "results": [
            {
                "index": 237,
                "summary": "乳がんのサブタイプについて"
            },
            {
                "index": 231,
                "summary": "乳がんのサブタイプについて"
            },
            {
                "index": 294,
                "summary": "乳がんのステージについて"
            }
        ],
        "type": "summarizations"
    }
}
NEW
{
    "response": {
        "option": {
            "1": {
                "text": "セカンドオピニオンについて"
            },
            "2": {
                "text": "医療者とのコミュニケーションについて"
            },
            "3": {
                "text": NaN
            }
        },
        "text": "お知りになりたいのは、セカンドオピニオンについてでしょうか？医療者とのコミュニケーションについてでしょうか？",
        "type": "interpreting"
    }
}
 */
        break
        default:
      }
      // console.log("parsed result ",parsedResponse)
      return {
        response: parsedResponse
      }
    }

    async querySurveyBot(qIndex = 0)
    {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      
      var raw = JSON.stringify({
        "text": qIndex
      });
      
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      // let botVersion=process.env.REACT_APP_CHAT_BOT_ENDPOINT.split(',')
      // let queryBotType = botVersion[bot]
      
      const r = await fetch(`${config.localAuth.apiServiceUrl}${process.env.REACT_APP_SURVEY_ENDPOINT}`, requestOptions)
        .then(response => response.json())
        .then(result => result?.response)
        .catch(error => false);
      return r
    }

}

export default new BotHelper()