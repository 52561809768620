import React, { Component, useEffect, useState } from 'react'
import { Stack, Box, Button, Paper } from '@mui/material'
import * as Loaders from '../../components/UIComponents/Loader'
import * as Icons from '../../components/UIComponents/Icons'
import * as Texts from '../../components/UIComponents/Text'
import * as Buttons from '../../components/UIComponents/Button'
import * as Images from '../../components/UIComponents/Images'
import Loader from '../../components/UIComponents/Loader'
import Carousel from 'react-material-ui-carousel'
import AlwaysScrollToBottom from '../../components/AlwaysScrollToBottom'
import TypingAnimation from '../../components/UIComponents/TypingAnimation'
import { getLinkPreview, getPreviewFromContent } from "link-preview-js"
import moment from 'moment'
import Link from '@mui/material/Link'
import GeneralHelper from '../../helpers/GeneralHelper'


const UrlListView = ({
    pathObj,
    image
}) => {
    // console.log("loaded path object", pathObj)
    let toRenderContents = []
    Object.keys(pathObj).map((key, idx) => {
        let targetObj = pathObj[key]
        if (!targetObj?.path || !targetObj?.url) return
        if (!GeneralHelper.validURL(targetObj?.url)) return
        toRenderContents.push(<UrlBubble
            key={`nurse-carousel-${idx}-${targetObj?.path}`}
            url={targetObj?.url}
            title={targetObj?.path}
            image={image}
        />)
    })

    return (<Stack direction={"column"}>
        {toRenderContents}
    </Stack>)
}
const UrlBubble = ({
    time,
    image,
    // typing = false,
    displayTime = 1500,
    url,
    title
}) => {
    const [display, updateDisplay] = useState(false)

    useEffect(() => {
        // how about always add a typing bubble?
        setTimeout(() => {
            updateDisplay(true)
        }, displayTime)
    }, [])

    // console.log("images", image)
    // console.log("is typing", typing)
    if (display) {
        return (
            <React.Fragment>
                <Stack
                    direction={"column"}
                    sx={{
                        maxWidth: .6,
                        bgcolor: 'primary.dark',
                        borderRadius: '0px 16px 16px 16px',
                        p: 2,
                        // ml: 1
                    }}
                >
                    <Texts.Paragraph
                        sx={{
                            color: 'primary.contrastText',
                        }}
                    >
                        {title}
                    </Texts.Paragraph>
                    <Link 
                        color="background.highlight"
                        href={url} target="_blank" >
                        全文を読む
                    </Link>
                </Stack>
                <AlwaysScrollToBottom />

            </React.Fragment>)
    }
    return (<Stack
        direction="column"
        justifyContent={"flex-start"}
        sx={{
            width: 1,
            // ml: 2,
            // mt: 2
        }}
    >
        <Stack direction={"row"}>
            {/*<Box>
                {image}
            </Box>*/}
            <Box
                sx={{
                    maxWidth: .6,
                    bgcolor: 'primary.dark',
                    borderRadius: '0px 16px 16px 16px',
                    p: 2,
                    ml: 1
                }}
            >
                <TypingAnimation fill="#F8F8F8" />
            </Box>
        </Stack>
    </Stack>)
}

const ContentPaper = ({
    url,
    title
}) => {
    console.log("got url", url)
    useEffect(() => {
        // getLinkPreview(url).then((data) => console.debug(data));

    }, [])

    return (<Paper elevation={4}
        sx={{
            maxWidth: 184,
            maxHeight: 240,
            width: 184,
            height: 240,
            px: 2,
            pt: 3,
            pb: 3
        }}
    >
        <Stack spacing={2} direction={"column"} justifyContent={'flex-start'}
            sx={{
                height: 1
            }}
        >
            <Texts.Title>{title.length >= 12 ? `${title.slice(0, 12)}...` : title}</Texts.Title>
            <Box
                sx={{
                    display: 'flex',
                    flexGrow: 1,
                    justifyContent: 'flex-start',
                    maxHeight: 90,
                    overflow: 'hidden'
                }}
            >
                <Texts.Tip>{url}</Texts.Tip>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    // flexGrow: 1,
                    justifyContent: 'flex-end'
                }}
            >
                <Link to={{ pathname: url }} target="_blank" >
                    <Texts.Paragraph sx={{
                        color: 'primary'
                    }}>全文を読む</Texts.Paragraph>
                </Link>
            </Box>
        </Stack>


    </Paper>)
}

export default UrlListView