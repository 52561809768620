import React, { Component, useMemo } from 'react'
import { bindActionCreators } from '@reduxjs/toolkit'
import { connect } from 'react-redux'
import methods from '../reducers/methods'
import * as ConfigActions from '../reducers/config'
import * as UserActions from '../reducers/user'
import * as SurveyActions from '../reducers/survey'
import CustomTheme from '../components/CustomTheme'
import {
    Grid,
    Typography,
    Drawer,
    Toolbar,
    Divider,
    Box,
    Stack,
    CssBaseline,
    List,
    ListItem
} from '@mui/material'
import AppNavigator2 from '../components/AppNavigator2'
import SendPanel from './ChatRoom/SendPanel'
import OptionPanel from './ChatRoom/OptionPanel'
import ChatWindow from './ChatRoom/ChatWindow'
import BotHelper from '../helpers/BotHelper'
import { withRouter } from 'react-router';
import * as Images from '../components/UIComponents/Images'
import config from '../config/config'
import moment from 'moment'
import ChatHelper from '../helpers/ChatHelper'
import GeneralHelper from '../helpers/GeneralHelper'
import AuthenticationHelper from '../helpers/AuthenticationHelper'

let { innerWidth, innerHeight } = window

let INNERWIDTH = innerWidth >= 600 ? 600 : innerWidth

const chatroomObj = {
    nurse: {
        title: "Ms. OICI",
        sx: {
            color: 'text.light',
            bgcolor: 'background.dark',
            justifyContent: 'center',
            alignItems: 'space-between'
        },
        image: 'nurseImg'

    },
    faqbot: {
        title: "FAQoid",
        sx: {
            color: 'primary.dark',
            bgcolor: 'secondary.main'
        },
        image: 'faqBotImg'
    }
}

const botImgObj = {
    faqBotImg: <Images.ChatbotPointerRight />,
    nurseImg: <Images.NursePointerRight />
}
// const safeTop = parseInt(getComputedStyle(document.documentElement).getPropertyValue("--sat").replace("px"), 10) || 0
// const safeBottom = parseInt(getComputedStyle(document.documentElement).getPropertyValue("--sab").replace("px"), 10) || 0
const safeTop = 0
const safeBottom = 0
// console.log("the safe top is ",safeTop, " and the safe bottom is ", safeBottom)


const chatInitialState = {
    inputMessage: "",
    messageThread: [],
    chatbotVersion: '',
    whichBot: 'nurse',
    chatbotOptions: [],
    chatbotOptionType: '',
    qIndex: -1,
    qObj: {},
    chatWindowWidth: INNERWIDTH,
    chatHeaderHeight: config.headerHeight,
    disableAnswer: false
}
class ChatRoom extends Component {
    constructor(props) {
        super(props)
        this.state = chatInitialState
        this.updateDimensions = this.updateDimensions.bind(this)
        this.updateMessage = this.updateMessage.bind(this)
        this.addMessage = this.addMessage.bind(this)
        this.determineNextQuestion = this.determineNextQuestion.bind(this)
        this.onResponseCallback = this.onResponseCallback.bind(this)
        this.triggerTimedResponse = this.triggerTimedResponse.bind(this)
        this.onReturn = this.onReturn.bind(this)
        this.onRestart = this.onRestart.bind(this)
        this.onBotQueryWithPath = this.onBotQueryWithPath.bind(this)
        this.addUrl = this.addUrl.bind(this)
        this.onSubmitAnswer=this.onSubmitAnswer.bind(this)
    }

    async componentDidMount() {
        console.log("chatroom loaded")
        const { match, location, user, configReducer, switchLanguage, survey, clearSurvey } = this.props
        const { messageArr, currentIndex } = survey
        const { dict, bot, lang } = configReducer
        window.addEventListener('resize', this.updateDimensions);

        const initHeaderElement = document.querySelector('header')
        let initHeaderHeight = initHeaderElement.offsetHeight || config.headerHeight;
        this.setState({
            chatHeaderHeight: initHeaderHeight
        })

        switchLanguage(lang)
        const currentLang = dict["chatWindow"][bot]
        // console.log('chat room props', this.props)

        const whichChatbot = match?.params?.subroom || "faqbot"
        this.setState({
            whichBot: whichChatbot
        })

        if (messageArr.length > 0) // there were previous messages, ask if user wishes to continue or restart
        {
            // console.log(typeof messageArr)
            /*
            let mutatedArr = [...messageArr]
            mutatedArr.pop()
            this.setState({
                messageThread: mutatedArr
            })
            let toRestart = window.confirm("入力途中のデータがあるようです。前回の続きから再開しますか？")
            if (!toRestart) {
                this.onResponseCallback(currentIndex.toString(), "bot")
                this.setState({
                    qIndex: currentIndex
                })
                // console.log("user doesn't want to restart", this.state.messageThread)
                return
            }

            //user wishes to restart, clear the input
            await clearSurvey()
            this.setState({
                messageThread: []
            })
            */
        }

        switch (whichChatbot) {
            case "faqbot":
                this.addMessage("がんに関する情報について、AI が自動応答します。ご質問をお書きください。", "bot")
                break
            case "nurse":
            default:
                this.addMessage(currentLang["Thank you for sharing your experience with us. Shall we start?"], "bot")
                this.onResponseCallback("0", "bot")
                this.setState({
                    qIndex: 0
                })
        }

        // let chatbotVersionsArr = process.env.REACT_APP_CHAT_BOT_VERSIONS.split(',')
        // // console.log("chatbot arr", chatbotVersionsArr)
        // let select = {}
        // chatbotVersionsArr.map((v, i) => {
        //     select[i] = v
        // })
        // this.setState({
        //     // chatbotVersion: 2,
        //     // chatbotSelect: select,
        //     whichBot: whichChatbot
        // })
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }
    updateDimensions() {
        // console.log("window resizing", window.innerWidth)
        const headerElement = document.querySelector('header')
        let headerHeight = headerElement.offsetHeight;
        // console.log(headerHeight)
        this.setState({
            chatWindowWidth: window.innerWidth >= 600 ? 600 : window.innerWidth,
            chatHeaderHeight: headerHeight
        })
    }

    async updateMessage(value, index = false) {
        // console.log("user input messages", value, index)
        // alert(value)
        this.addMessage(value, "user")
        const { qIndex, chatbotOptionType, chatbotOptions, qObj } = this.state
        const { match, location, user, resetSession } = this.props
        const whichChatbot = match?.params?.subroom || "nurse"

        // console.log("chatbot options",chatbotOptions)
        // first save the answer
        // this.addMessage(answer, "user")
        /*
        let optionsObj = chatbotOptions["1"]
        optionsObj["option_text"] = value
        let toBeStoredObj = {
            qID: qObj?.qID,
            surveyIndex: qObj?.survey_index,
            answer: JSON.stringify(optionsObj)
        }
        */
        // console.log(toBeStoredObj)
        // handle save the answer in the database
        // return
        // const saveResult = await ChatHelper.storeChatLogByUserId(user?.id, user?.sessionId, JSON.stringify(toBeStoredObj))
        // console.log("saved result", saveResult)

        // console.log("chatbot options", chatbotOptions)
        // console.log("query chatbot for answers")
        // decide which chatbot to query
        switch (whichChatbot) {
            case "faqbot":
                if(index && index == -1)
                {
                    // console.log("no options were selected")
                    await this.addMessage("大変申し訳ありませんが、お探しの情報が見つかりませんでした。次の質問を入力してください", "bot")
                    return
                }
                if(index && index === "該当なし")
                {
                    // console.log("no options were selected")
                    await this.addMessage("大変申し訳ありませんが、お探しの情報が見つかりませんでした。次の質問を入力してください", "bot")
                    return
                }
                const botAnswer = await BotHelper.queryBot(!index ? value:index, !index ? "question":"option")
                // console.log("bot answered", botAnswer)
                if (botAnswer?.response?.type === "answers") {
                    // console.log("render the answer")
                    if (botAnswer?.response?.results && botAnswer?.response?.results?.asnwer_text) {
                        // console.log("respond the answer")
                        // render answer normally
                        await this.addMessage(botAnswer?.response?.results?.asnwer_text, "bot")
                    }
                    if (botAnswer?.response?.results && botAnswer?.response?.results?.path_url) {
                        await this.onBotQueryWithPath(botAnswer?.response?.results?.path_url)
                    }
                }
                if (botAnswer?.response?.type === "summarizations") {
                    if (Array.isArray(botAnswer?.response?.results) && botAnswer?.response?.results.length > 0) {
                        let optionsObj = {}
                        botAnswer.response.results.map((result, idx) => {
                            if(result["summary"] === "-") return
                            const strIdx = idx.toString()
                            optionsObj[strIdx] = {
                                "option_text": result["summary"],
                                "option_index": result["index"] || result["summary"]
                            }
                        })
                        if(Object.keys(optionsObj) <= 0 ) // there were no answers
                        {
                            // return to the chat window
                            // 大変申し訳ございませんが、こちらでお答えするのは難しそうです。恐れ入りますが「がん電話相談」や「がんチャット相談」をご利用ください。相談員が直接お話を伺います。
                            await this.addMessage("大変申し訳ございませんが、こちらでお答えするのは難しそうです。恐れ入りますが「がん電話相談」や「がんチャット相談」をご利用ください。相談員が直接お話を伺います。", "bot")
                            return
                        }
                        let finalIdx = botAnswer.response.results.length
                        const finalIdxStr = finalIdx.toString()
                        optionsObj[finalIdxStr] = {
                            "option_text": "該当なし",
                            "option_index": -1
                        }
                        const summaryOptions = {
                            count: 0,
                            options: optionsObj,
                            qDesc: botAnswer?.response?.asnwer_text ? botAnswer?.response?.asnwer_text+" 回答を絞り込みますので、あなたが知りたいことを選択してください。": "回答を絞り込みますので、あなたが知りたいことを選択してください。",
                            qID: "END",
                            survey_index: 0,
                            type: "check"
                        }
                        await this.addMessage(summaryOptions["qDesc"], "bot")
                        // console.log('the prepared summary options are', summaryOptions)
                        this.setState({
                            disableAnswer: false,
                            chatbotOptionType: "check",
                            chatbotOptions: optionsObj,
                            qIndex: 1,
                            qObj: summaryOptions,
                        })
                        // return setTimeout(() => {
                        //     self.setState({
                        //         disableAnswer: false
                        //     })
                        // }, config.typingLatency)
                    }

                }
                /* DEPRECATED
                if(botAnswer?.response?.answer_text) {
                    await this.addMessage(botAnswer?.response?.answer_text, "bot")
                }
                if (botAnswer?.response?.asnwer_text) {
                    await this.addMessage(botAnswer?.response?.asnwer_text, "bot")
                }
                if(botAnswer?.response?.path_url)
                {
                    await this.onBotQueryWithPath(botAnswer?.response?.path_url)
                }
                if(botAnswer?.body) {
                    await this.addMessage(botAnswer?.body, "bot")
                }
                */
                return
            case "nurse":
            default: // in the case of nurse bot, the value should be the question index
                // console.log(`the entered value is ${value} and the type is ${parseInt(value, 10)}`)
                // let convertedInt = parseInt(value, 10)
                // console.log(`the conversted Int is `,convertedInt)
                // if(!convertedInt)
                // {
                // it's a text input
                let nextOption = chatbotOptions["1"]?.next_index || qIndex + 1
                return this.onResponseCallback(parseInt(nextOption, 10), "bot")
            // }
            // return this.onResponseCallback(convertedInt, "bot")
        }
        // this.setState({
        //     inputMessage: value
        // })
    }

    addMessage(msg, src) {
        const { match, location, survey, addAnswer, user } = this.props
        const { messageArr } = survey
        const whichChatbot = match?.params?.subroom || "nurse"
        // console.log("the default bot is ",whichChatbot)
        let botObj = chatroomObj[whichChatbot]
        let mutedMsg = [...this.state.messageThread]
        // console.log("received bot option", botObj)
        mutedMsg.push({
            message: msg,
            source: src,
            time: moment(),
            image: botImgObj[botObj["image"]]
        })
        ChatHelper.storeChatLogByUserId(user.id, user.sessionId, {
            "sender": src,
            "message": msg
        })
        /** if not using Redux, then comment out
        addAnswer({
            message: msg,
            source: src,
            time: moment().format('hh:mm'),
            image: botObj["image"]
        })
         */
        // console.log(mutedMsg)
        this.setState({
            messageThread: mutedMsg
        })
    }

    async determineNextQuestion(answerIndex = 0) {
        const {
            chatbotOptionType,
            chatbotOptions,
            qObj,
            qIndex
        } = this.state
        try {
            const answerKey = answerIndex.toString()
            const nextQuestionIndex = chatbotOptions.hasOwnProperty(answerKey) ? chatbotOptions[answerKey]?.next_index : qIndex + 1
            return nextQuestionIndex
        }
        catch (e) {
            return -1
        }
    }

    onReturn() {
        const { history } = this.props
        this.setState(chatInitialState)
        history.push("/")
    }

    onRestart() {
        this.setState(chatInitialState)
        this.onResponseCallback("0", "bot")
        // this.setState({
        //     qIndex: 0,
        //     messageThread: []
        // })
    }

    // for NCC bot, if there are paths, then render the path
    async onBotQueryWithPath(pathUrl = {}) {
        if (Object.keys(pathUrl).length <= 0) return
        console.log(pathUrl)
        this.addUrl(pathUrl, "bot")
        return
    }

    async addUrl(msg, src) {
        const { match, location } = this.props
        const whichChatbot = match?.params?.subroom || "faqbot"
        // console.log("the default bot is ",whichChatbot)
        let botObj = chatroomObj[whichChatbot]
        let mutedMsg = [...this.state.messageThread]
        // console.log("received bot option", botObj)
        mutedMsg.push({
            message: msg,
            source: src,
            time: moment(),
            image: botImgObj[botObj["image"]]
        })
        // console.log(mutedMsg)
        this.setState({
            messageThread: mutedMsg
        })
    }
    /** Anketo chatbot only */
    async onResponseCallback(qIndex = 0, src) {
        // console.log(`input qIndex `, qIndex)
        const { match, location, configReducer, updateSurveyIndex } = this.props
        const { dict, bot } = configReducer

        this.setState({
            disableAnswer: true
        })

        const botLang = dict["chatWindow"][bot]

        const whichChatbot = match?.params?.subroom || "faqbot"
        let botObj = chatroomObj[whichChatbot]
        let formattedQIndex = qIndex
        // use the option and answers to determine the next question
        if (typeof qIndex !== "string") {
            formattedQIndex = qIndex.toString()
        }
        const nextQ = await BotHelper.querySurveyBot(qIndex)
        updateSurveyIndex(formattedQIndex)
        // console.log("nextQ results", nextQ)
        if (nextQ && typeof nextQ === "string") {
            // console.log("you should not see me unless there's an end of the conversation")
            // this is the end of the conversation
            this.addMessage(botLang["Survey is completed, thanks for your contribution!"], src)
            this.setState({
                chatbotOptionType: "COMPLETE",
                disableAnswer: false,
                chatbotOptions: {
                    "1": {
                        "option_text": botLang["return"]
                    }
                    // "2": {
                    //     "option_text": botLang["return"]
                    // }
                },
                qIndex: -1,
                qObj: {
                    count: "END",
                    options: {
                        "1": {
                            "option_text": botLang["return"]
                        }
                    },
                    qDesc: "",
                    qID: "END",
                    survey_index: -1,
                    type: "radio"
                }
            })
            return
        }
        // console.log("got next question", nextQ)
        if (nextQ?.type === "display") {
            // console.log("next question is display ", nextQ)
            let nextIndex = nextQ?.options["1"]["next_index"] || nextQ?.survey_index + 1
            this.triggerTimedResponse(nextIndex)
        }
        this.addMessage(`${nextQ?.qID} ${nextQ?.qDesc}`, src)
        // add option to the chatwindow
        const self = this
        return setTimeout(() => {
            self.setState({
                chatbotOptionType: nextQ?.type,
                chatbotOptions: nextQ?.options,
                qIndex: nextQ?.survey_index || -1,
                qObj: nextQ,
                disableAnswer: false
            })
        }, config.typingLatency)

        // save the answer
    }

    triggerTimedResponse(qIndex = null) {
        if (!qIndex) return
        setTimeout(() => {
            this.onResponseCallback(qIndex, "bot")
        }, 1500)
    }

    async onSubmitAnswer(optionsArr, nextQuestionIndex, answer, surveyType) {
        // console.log("received answer ", answer, nextQuestionIndex)
        // console.log("submitted", answer, nextQuestionIndex)
        if (surveyType === "COMPLETE") // determine the next action
        {
            // console.log(answer)
            switch (answer) {
                case "restart":
                    this.onRestart()
                    break
                case "return":
                default:
                    this.onRestart()
                    this.onReturn()
            }
            return
        }


        // await this.addMessage(answer, "user")
        // console.log("next question index ", nextQuestionIndex)
        // console.log(typeof answer)
        // clear the answers first
        await this.setState({
            disableAnswer: false,
            chatbotOptions: {},
            chatbotOptionType: '',
            // qIndex: -1,
            qObj: {},
        })
        if(Array.isArray(nextQuestionIndex))
        {
            // console.log("answer is an array")
            // display both answers
            await GeneralHelper.asyncForEach(nextQuestionIndex, async (item) => {
                // console.log("answer item", item)
                await this.updateMessage(item, item)
            })
            return
        }
        await this.updateMessage(answer, nextQuestionIndex)
        return
        // const nextQ = await BotHelper.queryBot(parseInt(nextQuestionIndex, 10), "index")
        // console.log(nextQ)
    }

    render() {

        const {
            messageThread,
            chatbotVersion,
            chatbotSelect,
            whichBot,
            chatbotOptionType,
            chatbotOptions,
            qObj,
            qIndex,
            chatWindowWidth,
            disableAnswer,
            chatHeaderHeight
        } = this.state
        // console.log(`should disable answer ${disableAnswer}`)
        const { configReducer, user, resetSession, history } = this.props
        const { lang, dict, bot } = configReducer

        const botLang = dict["chatWindow"][bot]
        // console.log("chatbot options", chatbotOptionType)
        // console.log(`current qindex is ${qIndex}`)


        let shouldSendPanelDisplay = true
        if (chatbotOptions && Object.keys(chatbotOptions).length > 0 && !chatbotOptionType.includes('text')) {
            shouldSendPanelDisplay = false
        }
        if (qObj?.type === "datepick") {
            shouldSendPanelDisplay = false
        }
        let botObj = chatroomObj[whichBot]
        let chatWrapperHeight = innerHeight - chatHeaderHeight - safeTop - safeBottom
        let chatwindowHeight = innerHeight - chatHeaderHeight - config.sendPanelHeight - safeTop - safeBottom
        if (!shouldSendPanelDisplay) {
            chatwindowHeight = innerHeight - chatHeaderHeight - config.optionPanelHeight - safeTop - safeBottom
        }

        // console.log("the chatwindow height is ", chatwindowHeight, " and the chat warpper height is ", chatWrapperHeight)
        return <CustomTheme>
            <AppNavigator2
                title={botLang["Anketo Bot"]}
                sx={botObj["sx"]}
                image={botImgObj[botObj["image"]]}
                onBackClicked={() => {
                    const toConfirm = true
                    // const toConfirm = window.confirm("戻るをクリックするとデータが失われます。このまま続けますか？")
                    if (toConfirm) {
                        resetSession()
                        // this.setState(chatInitialState)
                        return history.goBack()
                        // return history.push('/')
                    }
                    return false
                }}
            />
            <Box
                sx={{
                    flexGrow: 1,
                    width: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                    bgcolor: 'background.gray',
                    height: chatWrapperHeight
                    // pt: 2
                }}
            >

                <Grid container
                    direction="column"
                    justifyContent="space-between"
                    // alignItems="flex-end"
                    sx={{
                        flexGrow: 1,
                        flexWrap: "wrap",
                        // width: 1,
                        maxWidth: chatWindowWidth,
                        bgcolor: 'background.default',
                        ml: 'auto',
                        mr: 'auto',
                        // borderRadius: 4,

                        // minHeight: chatWrapperHeight,
                        // maxHeight: chatWrapperHeight,
                        // minHeight: `${innerHeight - config.headerHeight}px`, // deduct header and chat
                        // maxHeight: `${innerHeight - config.headerHeight}px`,
                        // py: 2
                        // height: '100vh'
                    }}
                    style={{
                        // paddingTop: `env(safe-area-inset-top)`,
                        // paddingLeft: `env(safe-area-inset-left)`,
                        // paddingRight: `env(safe-area-inset-right)`,
                        // paddingBottom: `env(safe-area-inset-bottom)`,
                    }}
                >
                    <Grid item
                        sx={{
                            // flexGrow: 1,
                            minHeight: `${chatwindowHeight}px`, // deduct header and chat
                            maxHeight: `${chatwindowHeight}px`,
                            overflow: 'auto',
                            pb: 2
                        }}
                    >
                        <ChatWindow
                            messageArr={messageThread}
                        />
                    </Grid>

                </Grid>
                {!disableAnswer && <Box
                    sx={{
                        position: 'absolute',
                        bottom: shouldSendPanelDisplay ? config.sendPanelHeight : 0,
                        bgcolor: 'background.gray',
                        maxWidth: 1,
                        mr: 'auto',
                        ml: 'auto',
                        right: 0,
                        left: 0
                    }}
                >
                    <Box
                        sx={{
                            position: 'relative',
                            bottom: shouldSendPanelDisplay ? config.sendPanelHeight : 0,
                            maxWidth: chatWindowWidth,
                            mr: 'auto',
                            ml: 'auto',
                            // right: 0,
                            // left: 0
                        }}
                    >
                        <OptionPanel
                            options={chatbotOptions}
                            type={chatbotOptionType}
                            qIndex={qIndex}
                            qObj={qObj}
                            optionClickHint={botLang["Click above to confirm and send"]}
                            optionSubmitHint={botLang["Please select"]}
                            onSubmit={this.onSubmitAnswer
                            //     async (optionsArr, nextQuestionIndex, answer, surveyType) => {
                            //     console.log("submitted")
                            //     if (surveyType === "COMPLETE") // determine the next action
                            //     {
                            //         // console.log(answer)
                            //         switch (answer) {
                            //             case "restart":
                            //                 this.onRestart()
                            //                 break
                            //             case "return":
                            //             default:
                            //                 this.onRestart()
                            //                 this.onReturn()
                            //         }
                            //         return
                            //     }
                            //     this.addMessage(answer, "user")
                            //     console.log("next question index ", nextQuestionIndex)
                            //     this.setState({
                            //         disableAnswer: false
                            //     })
                            //     const nextQ = await BotHelper.queryBot(parseInt(nextQuestionIndex, 10), "index")
                            //     console.log(nextQ)
                            //     /**
                            //     // add the answer to user
                            //     this.addMessage(answer, "user")
                            //     let toBeStoredObj = {
                            //         qID: qObj?.qID,
                            //         surveyIndex: qObj?.survey_index,
                            //         answer: JSON.stringify(optionsArr)
                            //     }
                            //     // console.log(toBeStoredObj)
                            //     // handle save the answer in the database
                            //     const saveResult = await ChatHelper.storeChatLogByUserId(user?.id, user?.sessionId, JSON.stringify(toBeStoredObj))
                            //     const updateQuestion = await this.onResponseCallback(nextQuestionIndex, "bot")
                            //      */

                            // }
                        }
                        />
                    </Box>
                </Box>}
                {shouldSendPanelDisplay && !disableAnswer && <SendPanel
                    // options={chatbotOptions}
                    // type={chatbotOptionType}
                    // qIndex={qIndex}
                    // qObj={qObj}
                    sendMessage={this.updateMessage}
                />}
            </Box>

        </CustomTheme>
    }
}

// export default withRouter(ChatRoom)

let ChatRoomContainer = props => {
    const { dispatch, user, config } = props


    const boundActionCreators = useMemo(() => bindActionCreators({
        ...UserActions,
        ...ConfigActions,
        ...SurveyActions,
        resetSession: methods.RESET_SESSION
    }, dispatch), [dispatch])

    return <ChatRoom user={user} config={config} {...boundActionCreators} {...props} />
}

ChatRoomContainer = connect(state => ({ user: state.user, configReducer: state.config, survey: state.survey }))(ChatRoomContainer)

export default withRouter(ChatRoomContainer)

export { botImgObj }