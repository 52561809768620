import React, { useEffect, useState } from 'react'
import { Text } from './Text'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import SelectComponent from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import CheckboxItem from '@mui/material/Checkbox'
import FormHelperText from '@mui/material/FormHelperText'
import CustomTheme from '../CustomTheme'
import { Typography } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import moment from 'moment'

const DateTimeInput = React.forwardRef((props, ref) => {
    const [value, setValue] = useState(new Date())
    
    const {
        title,
        options,
        name,
        inputValue,
        handleInput
    } = props
    const handleChange = (newValue) => {
        setValue(newValue)
        let renderedDate = moment(newValue).format("YYYY-MM-DD hh:mm A")
        // console.log(`the rendered date is ${renderedDate}`)
        return handleInput(renderedDate)
    }
    return (<LocalizationProvider dateAdapter={AdapterMoment}>
        <CustomTheme>
            <FormControl fullWidth>
                <DateTimePicker
                    label={title}
                    value={value}
                    onChange={handleChange}
                    renderInput={(params) => <TextField {...params} />}
                />
            </FormControl>
        </CustomTheme>
    </LocalizationProvider>)
})


/**
 * usage:
    <TextInput 
        ref={password => this._password = password}
        type={this.state.hidePass ? "password" : "text"}
        label='Password'
        ariaLabel='password'
        icon='envelope'
        onChange={this.onChangehandler}
        error='your error text'
        helperText=''
    />
 */
const TextInput = React.forwardRef((props, ref) => {
    // console.log("text input props", props)

    const {
        // title,
        // options,
        // name,
        // inputValue,
        // handleInput,

        id,
        type,
        placeholder,
        label,
        value,
        onChange,
        withAdornment,
        icon,
        error,
        helperText,
        endAdornmentLabel,
        handleEndAdornmentClick,
        endAdornmentIcon
    } = props

    const defaultSx = {
        // mb: 5,
        ...props.sx
    }
    if (!withAdornment) {
        if (error && error !== '') {
            return (<CustomTheme>
                <FormControl error sx={defaultSx} fullWidth >
                    <Typography 
                        htmlFor={id} 
                        variant="body"
                    >{label}</Typography>
                    <Input
                        inputRef={ref}
                        id={id}
                        type={type}
                        placeholder={placeholder}
                        value={value}
                        onChange={onChange ? (e) => onChange(e) : null}
                        startAdornment={
                            <InputAdornment position="start">
                                <i className={`fas fa-${icon}`}></i>
                            </InputAdornment>
                        }
                    />
                    <FormHelperText >{error}</FormHelperText>
                </FormControl>
            </CustomTheme>)
        }
        return (<CustomTheme>
            <FormControl sx={defaultSx} fullWidth >
                {/*<InputLabel htmlFor={props.id}>{props.label}</InputLabel>*/}
                <Typography htmlFor={id} variant="body">{label}</Typography>
                <Input
                    inputRef={ref}
                    id={id}
                    type={type}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange ? (e) => onChange(e) : null}
                    startAdornment={
                        <InputAdornment position="start">
                            <i className={`fas fa-${icon}`}></i>
                        </InputAdornment>
                    }
                />
                {helperText !== '' ? <FormHelperText >{helperText}</FormHelperText> : null}
            </FormControl>
        </CustomTheme>)
    }
    if (error && error !== '') {
        return (<CustomTheme>
            <FormControl error sx={defaultSx} fullWidth >
                {/*<TextField 
                inputRef={ref}
                id={props.id}
                type={props.type}
                label={props.label} 
                variant="filled" 
                placeholder={props.placeholder}
                value={props.value}
                onChange={props.onChange ? (e) => props.onChange(e) : null}
                startAdornment={
                    <InputAdornment position="start">
                        <i className={`fas fa-${props.icon}`}></i>
                    </InputAdornment>
                }
                endAdornment={
                    <InputAdornment position="end">
                    <IconButton
                        aria-label={props.endAdornmentLabel}
                        onClick={props.handleEndAdornmentClick}
                    >
                    <i className={`fas fa-${props.endAdornmentIcon}`}></i>
                    </IconButton>
                    </InputAdornment>
                }
            />*/}
                <Typography htmlFor={id} variant="body">{label}</Typography>
                <Input
                    inputRef={ref}
                    id={id}
                    type={type}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange ? (e) => onChange(e) : null}
                    startAdornment={
                        <InputAdornment position="start">
                            <i className={`fas fa-${icon}`}></i>
                        </InputAdornment>
                    }
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label={endAdornmentLabel}
                                onClick={handleEndAdornmentClick}
                            >
                                <i className={`fas fa-${endAdornmentIcon}`}></i>
                            </IconButton>
                        </InputAdornment>
                    }
                />
                <FormHelperText >{error}</FormHelperText>
            </FormControl>
        </CustomTheme>)
    }
    return (<CustomTheme>
        <FormControl sx={defaultSx} fullWidth >
            <Typography htmlFor={id} variant="body">{label}</Typography>
            <Input
                inputRef={ref}
                id={id}
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={onChange ? (e) => onChange(e) : null}
                startAdornment={
                    <InputAdornment position="start">
                        <i className={`fas fa-${icon}`}></i>
                    </InputAdornment>
                }
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label={endAdornmentLabel}
                            onClick={handleEndAdornmentClick}
                        >
                            <i className={`fas fa-${endAdornmentIcon}`}></i>
                        </IconButton>
                    </InputAdornment>
                }
            />
            {helperText !== '' ? <FormHelperText >{helperText}</FormHelperText> : null}
        </FormControl>
    </CustomTheme>)
})

/**
 * usage:
    <TextArea 
        ref={password => this._password = password}
        type={this.state.hidePass ? "password" : "text"}
        label='Password'
        ariaLabel='password'
        icon='envelope'
        onChange={this.onChangehandler}
        rows={5}
    />
 */
const TextArea = React.forwardRef((props, ref) => {
    const sharedSx = {
        mb: 4,
        ...props.sx
    }
    if (props.hasOwnProperty('error') && props.error !== '') {
        return (<FormControl error className='w-100'>
            <TextField
                error={props.error !== ''}
                helperText={props.error || ''}
                inputRef={ref || null}
                id={props.id || ''}
                label={props.label}
                type={props.type}
                placeholder={props.placeholder}
                value={props.value}
                onChange={props.onChange ? (e) => props.onChange(e) : null}
                multiline
                rows={props.rows || 2}
                rowsMax={props.rows || 4}
                sx={sharedSx}
                {...props}
            />
        </FormControl>)
    }
    return (<FormControl className='w-100'>
        <TextField
            inputRef={ref || null}
            id={props.id || ''}
            label={props.label}
            type={props.type}
            placeholder={props.placeholder}
            value={props.value}
            onChange={props.onChange ? (e) => props.onChange(e) : null}
            multiline
            rows={props.rows || 2}
            rowsMax={props.rows || 4}
            sx={sharedSx}
            {...props}
        />
        {/*<FormHelperText>{props.helperText || ''}</FormHelperText>*/}
    </FormControl>)
})

const FormTextInput = React.forwardRef((props, ref) => {
    if (!props.withAdornment) {
        return (<>
            <div className="col-form-label col-md-6 col-sm-12 pt-0 d-flex justify-content-center align-items-sm-start flex-column"><Text className="mb-0">{props.title}</Text></div>
            <div className="select-container col-md-6 col-sm-12">
                <FormControl className='w-100'>
                    <Input
                        inputRef={ref}
                        id={props.id}
                        type={props.type}
                        placeholder={props.placeholder}
                        value={props.inputValue || ''}
                        onChange={props.handleInput ? (e) => props.handleInput(e, props.questionnaire, props.qid) : null}
                        startAdornment={
                            <InputAdornment position="start">
                                <i className={`fas fa-${props.icon}`}></i>
                            </InputAdornment>
                        }
                    />
                </FormControl>
            </div>
        </>)
    }
    return (<>
        <div className="col-form-label col-md-6 col-sm-12 pt-0 d-flex justify-content-center align-items-sm-start flex-column"><Text className="mb-0">{props.title}</Text></div>
        <div className="select-container col-md-6 col-sm-12">
            <FormControl className='w-100'>
                <Input
                    inputRef={ref}
                    id={props.id}
                    type={props.type}
                    placeholder={props.placeholder}
                    onChange={props.handleInput ? (e) => props.handleInput(e, props.questionnaire, props.qid) : null}
                    value={props.inputValue || ''}
                    startAdornment={
                        <InputAdornment position="start">
                            <i className={`fas fa-${props.icon}`}></i>
                        </InputAdornment>
                    }
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label={props.endAdornmentLabel}
                                onClick={props.handleEndAdornmentClick}
                            >
                                <i className={`fas fa-${props.endAdornmentIcon}`}></i>
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>
        </div>
    </>)
})
/**
 * usage:
    <Radio 
        ref={opt1 => this._opt1 = opt1}
        id="id"
        value={1}
        display="option 1"
        checked={false}
        inline={false}
        name="PG13-1"
    />
 */
const RadioItem = React.forwardRef((props, ref) => {
    if (!props?.value || !props.label) return null
    return (<FormControlLabel
        value={props?.value}
        control={<Radio />}
        checked={props?.checked}
        label={props?.label}
    />)
})
const FormGroup = React.forwardRef((props, ref) => {

    let defaultClassName = `form-group row fyw-form-group`

    if (props.hasOwnProperty('inComplete') && props.inComplete) {
        defaultClassName = `form-group row fyw-form-group fyw-form-group-incomplete`
    }
    return (
        <div ref={ref} className={props.className !== undefined ? `${defaultClassName} ${props.className}` : defaultClassName}>
            {props.children}
        </div>
    )
})

const RadioSet = React.forwardRef((props, ref) => {
    let renderArr = []
    props.options.forEach(item => {
        renderArr.push(<RadioItem
            key={item.id}
            id={item.id}
            value={item.value}
            label={item.display}
            name={props.name}
            checked={props.inputValue !== -1 && (parseInt(props.inputValue, 10) === parseInt(item.value, 10))}
        />)
    })
    return (<>
        <div className="col-form-label col-md-6 col-sm-12 pt-0 d-flex justify-content-center align-items-sm-start flex-column"><Text className="mb-0">{props.title}</Text></div>
        <div className="select-container col-md-6 col-sm-12">
            {props.inline ?
                <RadioGroup sx={{ justifyContent: 'space-evenly', display: 'flex' }} aria-label={props.name} name={props.name} onChange={(e) => props.handleInput(e, props.questionnaire, props.qid)} value={props.inputValue || -1} row>{renderArr}</RadioGroup>
                :
                <RadioGroup aria-label={props.name} name={props.name} onChange={(e) => props.handleInput(e, props.questionnaire, props.qid)} value={props.inputValue || -1}>{renderArr}</RadioGroup>
            }
        </div>
    </>)
    // return (<>
    //     <div className="col-form-label col-sm-12 pt-0 d-flex justify-content-center align-items-md-start align-items-sm-start flex-column"><Text className="mb-0">{props.title}</Text></div>
    //     <div className="col-sm-12 form-inline">{renderArr}</div>
    // </>)
})


const SearchSelect = React.forwardRef((props, ref) => {
    // console.log("searchselect called", props)
    const [value, setValue] = React.useState(null);
    const [inputValue, setInputValue] = React.useState('')

    const {
        title,
        options,
        name,
        handleInput
    } = props

    useEffect(() => {
        // updateValue(inputValue)
        setValue(options[0])
    }, [])

    if(!options) return null

    return <CustomTheme>
        <Autocomplete
            disablePortal
            id={name}
            options={options}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label={title} />}
            // onChange={(e, val) => {
            //     console.log("the selected event is ", e)
            //     console.log("the selected value is ", val)
            //     const v=val?.value
            // }}
            value={value}
            onChange={(event, newValue) => {
                setValue(newValue);
                handleInput(newValue?.value)
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
        />
    </CustomTheme>
    // return <CustomTheme>
    //     <SelectSearch options={options} value={value} name={name} placeholder={placeholder} />
    // </CustomTheme>
})

/**
 * usage:
    const optionsArr = [
        {
            value: '1',
            display: 'option 1'
        }
    ]
    <Select 
        options={optionsArr}
        id="select-1"
        title="Select one"
        name="select1"
    />
 */
const Select = React.forwardRef((props, ref) => {
    let options = []
    props.options.forEach((option, idx) => {
        options.push(<option key={`${props.id}-option-${idx}`} id={option.id} value={option.value}>{option.display}</option>)
    })
    return (<>
        <div className="col-form-label col-md-6 col-sm-12 pt-0 d-flex justify-content-center align-items-sm-start flex-column"><Text className="mb-0">{props.title}</Text></div>
        <div className="select-container col-md-6 col-sm-12">
            <select ref={ref} id={props.id} name={props.name} value={props.inputValue || 0} className="form-control" onChange={(e) => props.handleInput(e, props.questionnaire, props.qid)}>
                {options}
            </select>
        </div>
    </>)
})

/**
 * usage:
    <SelectItem 
        ref={r => this._select = r}
        label='Your Relationship'
        id=''
        inputValue='1'
        handleInput={e => console.log(e)}
    />
 */
const SelectItem = React.forwardRef((props, ref) => {
    let options = []
    const sharedSx = {
        mb: 4,
        ...props.sx
    }
    props.options.forEach((option, idx) => {
        options.push(<MenuItem key={`${props.id}-option-${idx}`} id={option.id} value={option.value}>{option.display}</MenuItem>)
    })
    if (props.hasOwnProperty('error') && props.error !== '') {
        return (<CustomTheme>
            <FormControl error fullWidth>
                <InputLabel>{props.label}</InputLabel>
                <SelectComponent
                    {...props}
                    ref={ref}
                    id={props.id || ''}
                    name={props.name}
                    value={props.inputValue || ''}
                    sx={sharedSx}
                    // className="form-control" 
                    onChange={(e) => props.handleInput(e)}>
                    {options}
                </SelectComponent>
                {props.hasOwnProperty('error') && props.error !== '' && <FormHelperText>{props.error}</FormHelperText>}
            </FormControl>
        </CustomTheme>)
    }
    return (<CustomTheme>
        <FormControl fullWidth>
            <InputLabel>{props.label}</InputLabel>
            <SelectComponent ref={ref} id={props.id || ''} {...props}
                name={props.name}
                value={props.inputValue || ''}
                // className="form-control" 
                sx={sharedSx}
                onChange={(e) => props.handleInput(e)}>
                {options}
            </SelectComponent>
        </FormControl>
    </CustomTheme>)
})


/**
 * usage:
    <Checkbox 
        label='label'
        checked={true}
        onChange={e => console.log('changed')}
    />
 */

const Checkbox = props => {
    if (props.hasOwnProperty('error') && props.error !== '') {
        return <FormControl error className='w-100'>
            <FormControlLabel
                control={<CheckboxItem color={props.color || "primary"} checked={props.checked} onChange={props.onChange} name={props.name || ''} />}
                label={props.label}
            />
            <FormHelperText>{props.error}</FormHelperText>
        </FormControl>
    }
    return <FormControl className='w-100'>
        <FormControlLabel
            control={<CheckboxItem color={props.color || "primary"} checked={props.checked} onChange={props.onChange} name={props.name || ''} />}
            label={props.label}
        />
    </FormControl>
}

export { SearchSelect, TextInput, TextArea, RadioItem, Select, FormGroup, RadioSet, FormTextInput, SelectItem, Checkbox, DateTimeInput }