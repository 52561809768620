import { createSlice, createAction } from '@reduxjs/toolkit'
import initLang, { defaultLang, langPack } from '../lang/init'

const initConfig = {
  lang: defaultLang,
  bot: 'faqbot', // or faqbot
  dict: initLang(defaultLang)
}

export const appConfig = createSlice({
  name: 'appConfig',
  initialState: initConfig,
  reducers: {
    switchBot: (state, action) => {
      state.bot = action.payload
    },
    switchLanguage: (state, action) => {
      if (langPack.hasOwnProperty(action.payload)) {
        state.lang = action.payload
        state.dict = initLang(action.payload)
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAction("RESET_REDUCER"), (state, action) => {
        // console.log("user reducer receives action", action)
        state = initConfig
      })
      .addDefaultCase((state, action) => {
        state = initConfig
      })
  },
})

// Action creators are generated for each case reducer function
export const { switchLanguage, switchBot } = appConfig.actions

export default appConfig.reducer