import React, { createRef, useState } from 'react'
import {
    Box,
    Stack
} from '@mui/material'
import * as Images from '../../components/UIComponents/Images'
import * as Buttons from '../../components/UIComponents/Button'
import * as Texts from '../../components/UIComponents/Text'
import * as Forms from '../../components/UIComponents/Form'

const { innerWidth } = window

const FAQBotOnboarding = ({
    headerText,
    descriptionText,
    call2ActionText,
    onClick
}) => {
    const [userId, updateUserId] = useState("")

    return (
        <Stack
            direction="column"
            justifyContent={"center"}
            spacing={{
                xs: 4,
                sm: 6,
                md: 10
            }}
            sx={{
                pt: 6,
                px: 6
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <Images.ChatbotOnboarding />
            </Box>
            <Stack
                direction="column"
                justifyContent={"center"}
                spacing={{
                    xs: 3,
                    sm: 4,
                    md: 5
                }}
                sx={{
                    textAlign: 'center'
                }}
            >
                <Texts.H4>{`${headerText} ${process.env.REACT_APP_VERSION}`}</Texts.H4>
                <Texts.Text>{descriptionText}</Texts.Text>
            {/*</Stack>
            <Stack
                direction={"column"}
                justifyContent={"center"}
                spacing={2}
            >*/}
                <Forms.TextInput
                    // ref={userid => userId = userid}
                    type={"text"}
                    label='ユーザーID'
                    ariaLabel='user_id'
                    icon='envelope'
                    onChange={e => updateUserId(e.target.value)}
                    error=''
                    helperText=''
                    sx={{
                        color: "primary.main"
                    }}
                >
                </Forms.TextInput>
                <Buttons.Button
                    onClick={() => onClick(userId)}
                >
                    <Texts.H4 sx={{ color: "primary.contrastText" }}>{call2ActionText}</Texts.H4>
                </Buttons.Button>
            </Stack>
            {/*<Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <Texts.Paragraph>What is FAQoid?</Texts.Paragraph>
            </Box>*/}
        </Stack>
    )

}
export default FAQBotOnboarding